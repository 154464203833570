//
// Scrollbars
// --------------------------------------------------


.scrollable::-webkit-scrollbar {
  height: 16px;
  width: 10px;

  &:hover {
    background-color: #f3f3f3;
    border: 1px solid #dbdbdb;
  }
}
.scrollable::-webkit-scrollbar-button:start:decrement,
.scrollable::-webkit-scrollbar-button:end:increment {
  background-color: transparent;
  display: block;
  height: 0;
}
.scrollable::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: solid
  transparent;
  border-width: 0 0 0 4px;
}
.scrollable::-webkit-scrollbar-track-piece {
  background-color: transparent;
  border-radius: 0;
}
.scrollable::-webkit-scrollbar-thumb {
  background-color: #515151; //old browsers fallback (ie8 etc)
  background-color: rgba(0,0,0,0.2);
  border: solid transparent;
  border-width: 0;
  .box-shadow(~"inset 1px 1px 0 rgba(0,0,0,0.10),inset 0 -1px 0 rgba(0,0,0,0.07)");
  background-clip: padding-box;

  &:hover {
    background-color: #949494;
  }
  &:active {
    background-color: #3b3b3b; //old browsers fallback (ie8 etc)
    background-color: rgba(0,0,0,0.5);
    .box-shadow(inset 1px 1px 3px rgba(0,0,0,0.35));
  }

  &:vertical,
  &:horizontal {
    background-color: #c6c6c6;
    border-radius: 0;
  }
}
