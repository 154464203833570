//
// Progress bars
// --------------------------------------------------


// Bar itself
// -------------------------

// Outer container
.progress {
  border: 1px solid #999;
  padding: 1px;
  height: 14px;
  font-size: @font-size-small;
  height: @line-height-computed;
  margin-bottom: @line-height-computed;
  background-color: transparent;
  background-image: none;
  border-radius: @progress-border-radius;
  .box-shadow(none);
}

// Bar of progress
.progress-bar {
  line-height: @line-height-xs;
  background-color: @progress-bar-bg;
  background-image: none;
  .box-shadow(none);
}

// Striped bars
//
// `.progress-striped .progress-bar` is deprecated as of v3.2.0 in favor of the
// `.progress-bar-striped` class, which you just add to an existing
// `.progress-bar`.
.progress-striped .progress-bar,
.progress-bar-striped {
  #gradient > .striped();
  background-size: 40px 40px;
}



// Variations
// -------------------------

.progress-bar-success {
  .progress-bar-variant(@progress-bar-success-bg);
}

.progress-bar-info {
  .progress-bar-variant(@progress-bar-info-bg);
}

.progress-bar-warning {
  .progress-bar-variant(@progress-bar-warning-bg);
}

.progress-bar-danger {
  .progress-bar-variant(@progress-bar-danger-bg);
}
