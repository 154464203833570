//
// Navbars
// --------------------------------------------------


// Wrapper and base class
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
  min-height: @navbar-height; // Ensure a navbar always shows (e.g., without a .navbar-brand in collapsed mode)
  margin-bottom: @navbar-margin-bottom;

  @media (min-width: @grid-float-breakpoint) {
    border-radius: @navbar-border-radius;
  }
}



// Brand/project name

.navbar-brand {
  padding: @navbar-padding-vertical @navbar-padding-horizontal;
  font-size: @font-size-large;
  line-height: @line-height-computed;
  height: @navbar-height;

  // Prevent Glyphicons from increasing height of navbar
  > .glyphicon {
    margin-top: 0;
  }

  @media (min-width: @grid-float-breakpoint) {
    .navbar > .container &,
    .navbar > .container-fluid & {
      margin-left: -@navbar-padding-horizontal;
    }
  }
}


// Navbar toggle
//
// Custom button for toggling the `.navbar-collapse`, powered by the collapse
// JavaScript plugin.

.navbar-toggle {
  margin-right: @navbar-padding-horizontal;
  padding: @padding-base-vertical 10px;
  .navbar-vertical-align(@input-height-small);
  border-radius: @border-radius-base;
}


// Navbar nav links
//
// Builds on top of the `.nav` components with its own modifier class to make
// the nav the full height of the horizontal nav (above 768px).

.navbar-nav {
  margin: floor((@navbar-padding-vertical / 2)) -@navbar-padding-horizontal;

  > li > a {
    padding-top:    @navbar-padding-vertical;
    padding-bottom: @navbar-padding-vertical;
    line-height: @line-height-computed;
  }

  @media (max-width: @grid-float-breakpoint-max) {
    // Dropdowns get custom display when collapsed
    .open .dropdown-menu {
      > li > a {
        line-height: @line-height-computed;
      }
    }
  }

  // Uncollapse the nav
  @media (min-width: @grid-float-breakpoint) {
    margin: 0;

    > li {
      > a {
        padding-top:    @navbar-padding-vertical;
        padding-bottom: @navbar-padding-vertical;
      }
    }
  }
}


// Navbar form
//
// Extension of the `.form-inline` with some extra flavor for optimum display in
// our navbars.

.navbar-form {
  margin-left: -@navbar-padding-horizontal;
  margin-right: -@navbar-padding-horizontal;
  padding: 10px @navbar-padding-horizontal;
  .box-shadow(none);

  // Mixin behavior for optimum display
  &:extend(.form-inline all);

  > .input-group .form-control {
    .navbar-vertical-align(@input-height-small);
  }

  // Vertically center in expanded, horizontal navbar
  .navbar-vertical-align((@input-height-small + 2));

  // Undo 100% width for pull classes
  @media (min-width: @grid-float-breakpoint) {
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .form-control {
    height: @input-height-small;
    padding: @padding-small-vertical @padding-small-horizontal;
  }
}


// Buttons in navbars
//
// Vertically center a button within a navbar (when *not* in a form).

.navbar-btn,
.navbar .btn {
  padding: @padding-small-vertical @padding-small-horizontal;
  .navbar-vertical-align(@input-height-small);

  &.btn-sm {
    .navbar-vertical-align(@input-height-small);
  }
  &.btn-xs {
    padding: @padding-xs-vertical @padding-xs-horizontal;
    .navbar-vertical-align(20);
  }
}


// Text in navbars
//
// Add a class to make any element properly align itself vertically within the navbars.

.navbar-text {
  .navbar-vertical-align(@line-height-computed);
}


// Alternate navbars
// --------------------------------------------------

// Default navbar
.navbar-default {
  background-color: @navbar-default-bg;
  border-color: @navbar-default-border;

  .navbar-brand {
    color: @navbar-default-brand-color;
    &:hover,
    &:focus {
      color: @navbar-default-brand-hover-color;
      background-color: @navbar-default-brand-hover-bg;
    }
    > .caret {
      border-top-color: @navbar-default-brand-color;
      border-bottom-color: @navbar-default-brand-color;
    }
  }

  .navbar-text {
    color: @navbar-default-color;
  }

  .navbar-nav {
    > li > a {
      color: @navbar-default-link-color;

      &:hover,
      &:focus {
        color: @navbar-default-link-hover-color;
        background-color: @navbar-default-link-hover-bg;
      }
    }
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-default-link-active-color;
        background-color: @navbar-default-link-active-bg;
      }
    }
    > .disabled > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-default-link-disabled-color;
        background-color: @navbar-default-link-disabled-bg;
      }
    }
  }

  .navbar-toggle {
    border-color: @navbar-default-toggle-border-color;
    &:hover,
    &:focus {
      background-color: @navbar-default-toggle-hover-bg;
    }
    .icon-bar {
      background-color: @navbar-default-toggle-icon-bar-bg;
    }
  }

  .navbar-collapse,
  .navbar-form {
    border-color: @navbar-default-border;
  }

  // Dropdown menu items
  .navbar-nav {
    // Remove background color from open dropdown
    > .open > a {
      &,
      &:hover,
      &:focus {
        background-color: @navbar-default-link-active-bg;
        color: @navbar-default-link-active-color;
      }
    }

    @media (max-width: @grid-float-breakpoint-max) {
      // Dropdowns get custom display when collapsed
      .open .dropdown-menu {
        > li > a {
          color: @navbar-default-link-color;
          &:hover,
          &:focus {
            color: @navbar-default-link-hover-color;
            background-color: @navbar-default-link-hover-bg;
          }
        }
        > .active > a {
          &,
          &:hover,
          &:focus {
            color: @navbar-default-link-active-color;
            background-color: @navbar-default-link-active-bg;
          }
        }
        > .disabled > a {
          &,
          &:hover,
          &:focus {
            color: @navbar-default-link-disabled-color;
            background-color: @navbar-default-link-disabled-bg;
          }
        }
      }
    }
  }


  // Links in navbars
  //
  // Add a class to ensure links outside the navbar nav are colored correctly.

  .navbar-link {
    color: @navbar-default-link-color;
    &:hover {
      color: @navbar-default-link-hover-color;
    }
  }

  .btn-link {
    color: @navbar-default-link-color;
    &:hover,
    &:focus {
      color: @navbar-default-link-hover-color;
    }
    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:focus {
        color: @navbar-default-link-disabled-color;
      }
    }
  }
}

// Inverse navbar

.navbar-inverse {
  background-color: @navbar-inverse-bg;
  border-color: @navbar-inverse-border;

  .navbar-brand {
    color: @navbar-inverse-brand-color;
    &:hover,
    &:focus {
      color: @navbar-inverse-brand-hover-color;
      background-color: @navbar-inverse-brand-hover-bg;
    }
    > .caret {
      border-top-color: @navbar-default-brand-color;
      border-bottom-color: @navbar-default-brand-color;
    }
  }

  .navbar-text {
    color: @navbar-inverse-color;
  }

  .navbar-nav {
    > li > a {
      color: @navbar-inverse-link-color;

      &:hover,
      &:focus {
        color: @navbar-inverse-link-hover-color;
        background-color: @navbar-inverse-link-hover-bg;
      }
    }
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-inverse-link-active-color;
        background-color: @navbar-inverse-link-active-bg;
      }
    }
    > .disabled > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-inverse-link-disabled-color;
        background-color: @navbar-inverse-link-disabled-bg;
      }
    }
  }

  // Darken the responsive nav toggle
  .navbar-toggle {
    border-color: @navbar-inverse-toggle-border-color;
    &:hover,
    &:focus {
      background-color: @navbar-inverse-toggle-hover-bg;
    }
    .icon-bar {
      background-color: @navbar-inverse-toggle-icon-bar-bg;
    }
  }

  .navbar-collapse,
  .navbar-form {
    border-color: darken(@navbar-inverse-bg, 7%);
  }

  // Dropdowns
  .navbar-nav {
    > .open > a {
      &,
      &:hover,
      &:focus {
        background-color: @navbar-inverse-link-active-bg;
        color: @navbar-inverse-link-active-color;
      }
    }

    @media (max-width: @grid-float-breakpoint-max) {
      // Dropdowns get custom display
      .open .dropdown-menu {
        > .dropdown-header {
          border-color: @navbar-inverse-border;
        }
        .divider {
          background-color: @navbar-inverse-border;
        }
        > li > a {
          color: @navbar-inverse-link-color;
          &:hover,
          &:focus {
            color: @navbar-inverse-link-hover-color;
            background-color: @navbar-inverse-link-hover-bg;
          }
        }
        > .active > a {
          &,
          &:hover,
          &:focus {
            color: @navbar-inverse-link-active-color;
            background-color: @navbar-inverse-link-active-bg;
          }
        }
        > .disabled > a {
          &,
          &:hover,
          &:focus {
            color: @navbar-inverse-link-disabled-color;
            background-color: @navbar-inverse-link-disabled-bg;
          }
        }
      }
    }
  }

  .navbar-link {
    color: @navbar-inverse-link-color;
    &:hover {
      color: @navbar-inverse-link-hover-color;
    }
  }

  .btn-link {
    color: @navbar-inverse-link-color;
    &:hover,
    &:focus {
      color: @navbar-inverse-link-hover-color;
    }
    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:focus {
        color: @navbar-inverse-link-disabled-color;
      }
    }
  }
}
