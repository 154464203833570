//
// Thumbnails
// --------------------------------------------------


// Mixin and adjust the regular image class
.thumbnail {
  display: block;
  padding: @thumbnail-padding;
  margin-bottom: @line-height-computed;
  line-height: @line-height-base;
  background-color: @thumbnail-bg;
  border: 1px solid @thumbnail-border;
  border-radius: @thumbnail-border-radius;

  // Add a hover state for linked versions only
  a&:hover,
  a&:focus,
  a&.active {
    border-color: @thumbnail-border;
    .box-shadow(0 0 0 1px #dedede);
  }

  // Image captions
  .caption {
    padding: @thumbnail-caption-padding;
    color: @thumbnail-caption-color;
  }
}
