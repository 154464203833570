//
// Jumbotron
// --------------------------------------------------


.jumbotron {
  color: @jumbotron-color;
  background-color: @jumbotron-bg;

  h1,
  .h1 {
    color: @jumbotron-heading-color;
  }

  p {
    font-size: @jumbotron-font-size;
  }

  .container &,
  .container-fluid & {
    border-radius: @border-radius-large; // Only round corners at higher resolutions if contained in a container
  }

  @media screen and (min-width: @screen-sm-min) {
    h1,
    .h1 {
      font-size: @jumbotron-heading-font-size;
    }
  }
}
