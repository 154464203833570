//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
  padding: @breadcrumb-padding-vertical @breadcrumb-padding-horizontal;
  margin-bottom: @line-height-computed;
  background-color: @breadcrumb-bg;
  border-radius: @border-radius-base;

  > li {
    + li {
      .chevron(right, 5px, @breadcrumb-bg, 9px, 1px, #777);
      margin-left: 20px;
    }
    + li:before,
    + li:after {
      left: -16px;
    }
    + li:before {
      content: "";
      color: @breadcrumb-color;
    }
    > a {
      color: @breadcrumb-color;
      &:hover {
        color: @breadcrumb-active-color;
      }
    }
  }

  > .active,
  > .active > a {
    color: @breadcrumb-active-color;
  }
}

.breadcrumb-inverse {
  background-color: @breadcrumb-inverse-bg;

  > li {
    + li {
      .chevron(right, 5px, @breadcrumb-inverse-bg, 9px, 1px, #666);
    }
    + li:before,
    + li:after {
      left: -16px;
    }
    > a {
      color: @breadcrumb-inverse-color;
      &:hover {
        color: @breadcrumb-inverse-active-color;
      }
    }
  }

  > .active,
  > .active > a {
    color: @breadcrumb-inverse-active-color;
  }
}

.breadcrumb-sm {
  padding: @breadcrumb-small-padding-vertical @breadcrumb-small-padding-horizontal;
  background-color: @breadcrumb-small-bg;
  border-bottom: 1px solid @breadcrumb-small-border;

  > li {
    + li {
      .chevron(right, 5px, @breadcrumb-small-bg, 9px, 1px, #666);
    }
    + li:before,
    + li:after {
      left: -16px;
    }
  }

  &.breadcrumb-inverse {
    background-color: @breadcrumb-inverse-bg;

  > li {
    + li {
      .chevron(right, 5px, @breadcrumb-inverse-bg, 9px, 1px, #666);
    }
    + li:before,
    + li:after {
      left: -16px;
    }
  }
}
}
