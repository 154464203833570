//
// Modals
// --------------------------------------------------

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and shit

// Actual modal
.modal-content {
  color: @gray-darker;
  border: 1px solid @modal-content-fallback-border-color; //old browsers fallback (ie8 etc)
  border: 1px solid @modal-content-border-color;
  border-radius: 0;
  .box-shadow(0 4px 16px rgba(0, 0, 0, 0.2));
}

// Modal background
.modal-backdrop {
  background-color: @modal-backdrop-bg;
}

// Close icon
.modal-header .close {
  font-weight: normal;
  .opacity(.4);
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
   padding: @modal-inner-padding;
}
