// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

.button-variant(@start-color; @end-color; @color: #fff; @border-color: @btn-default-border) {
  border: 1px solid @border-color;
  color: @color;
  #gradient > .vertical(@start-color: @start-color; @end-color: @end-color);
  .reset-filter();
  text-shadow: 0 1px rgba(0, 0, 0, 0.1);

  &:hover {
    text-shadow: 0 1px rgba(0, 0, 0, 0.3);
    .box-shadow(0 1px 1px rgba(0, 0, 0, 0.2));
  }

  // in these cases the gradient won't cover the background, so we override
  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    color: @color;
    border: 1px solid darken(@border-color, 5%);
    #gradient > .vertical(@start-color: @start-color; @end-color: darken(@end-color, 5%));
    .reset-filter();
    background-color: darken(@end-color, 5%);
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    border: 1px solid darken(@border-color, 10%);
    text-shadow: 0 1px rgba(0, 0, 0, 0.3);
    #gradient > .vertical(@start-color: @start-color; @end-color: darken(@end-color, 10%));
    .reset-filter();
    .box-shadow(inset 0 1px 2px rgba(0, 0, 0, 0.3));
  }
  &:focus,
  &.focus {
    &:not(:active):not(.active) {
      border: 1px solid @border-color;
      .box-shadow(inset 0 0 0 1px #fff);
    }
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      border: 1px solid @border-color;
      #gradient > .vertical(@start-color: @start-color; @end-color: @end-color);
      .reset-filter();
      background-color: @start-color;
      .box-shadow(none);
    }
  }

  .badge {
    color: @border-color;
    background-color: @color;
  }
}
