//
// Tooltips
// --------------------------------------------------


// Base class
.tooltip {
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  .reset-text();
  font-size: @font-size-mini;
  font-weight: bold;
  line-height: @line-height-xs;

  word-break: break-word;

  &.in     { .opacity(@tooltip-opacity); }
}

// Wrapper for the tooltip content
.tooltip-inner {
  padding: @tooltip-padding-vertical @tooltip-padding-horizontal;
  background-color: @tooltip-bg;
  border: 1px solid #fff;
  border-radius: 0;
}

// Arrows
.tooltip-arrow:before {
  content: " ";
  position: absolute;
  z-index: -1;
  border: @tooltip-arrow-outer-width solid transparent;
}
// Note: Deprecated .top-left, .top-right, .bottom-left, and .bottom-right as of v3.3.1
.tooltip {
  &.top .tooltip-arrow,
  &.top-left .tooltip-arrow,
  &.top-right .tooltip-arrow  {
    bottom: 1px;
    border-top-color: @tooltip-arrow-color;
    &:before {
      top: -@tooltip-arrow-width;
      left: -@tooltip-arrow-outer-width;
      border-top-color: @tooltip-arrow-outer-color;
      border-bottom: 0 dotted;
    }
  }
  &.right .tooltip-arrow {
    left: 1px;
    border-right-color: @tooltip-arrow-color;
    &:before {
      top: -@tooltip-arrow-outer-width;
      right: -@tooltip-arrow-width;
      border-right-color: @tooltip-arrow-outer-color;
      border-left: 0 dotted;
    }
  }
  &.left .tooltip-arrow {
    right: 1px;
    border-left-color: @tooltip-arrow-color;
    &:before {
      top: -@tooltip-arrow-outer-width;
      left: -@tooltip-arrow-width;
      border-right: 0 dotted;
      border-left-color: @tooltip-arrow-outer-color;
    }
  }
  &.bottom .tooltip-arrow,
  &.bottom-left .tooltip-arrow,
  &.bottom-right .tooltip-arrow {
    top: 1px;
    border-bottom-color: @tooltip-arrow-color;
    &:before {
      bottom: -@tooltip-arrow-width;
      left: -@tooltip-arrow-outer-width;
      border-top: 0 dotted;
      border-bottom-color: @tooltip-arrow-outer-color;
    }
  }
}
