// Navbar vertical align
//
// Vertically center elements in the navbar.
// Example: an element has a height of 30px, so write out `.navbar-vertical-align(30px);` to calculate the appropriate top margin.
//
// Example: an element has a height of 30px and you want to vertically center it in a bar other than the navbar:
//          .navbar-vertical-align(32px; @navbar-toolbar-height);

.navbar-vertical-align(@element-height; @bar-height: @navbar-height) {
  margin-top: ((@bar-height - @element-height) / 2);
  margin-bottom: ((@bar-height - @element-height) / 2);
}
