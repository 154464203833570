//
// Input groups
// --------------------------------------------------

// Sizing options
//
// Remix the default form control sizing classes into new ones for easier
// manipulation.

.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  .input-lg();
}
.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  .input-sm();
}


// Display as table-cell
// -------------------------
.input-group-addon,
.input-group-btn,
.input-group .form-control {
  margin: 0;
  border-radius: 0;
}

// Text input groups
// -------------------------
.input-group-addon {
  padding: @padding-base-vertical @padding-base-horizontal;
  font-size: @font-size-base;
  color: @input-color;
  border: 1px solid @input-group-addon-border-color;
  border-top-color: darken(@input-border, 10%);
  border-radius: @input-border-radius;

  // Sizing
  &.input-sm {
    padding: @padding-small-vertical @padding-small-horizontal;
    font-size: @font-size-small;
    border-radius: @input-border-radius-small;
  }
  &.input-lg {
    padding: @padding-large-vertical @padding-large-horizontal;
    font-size: @font-size-large;
    border-radius: @input-border-radius-large;
  }

  // Nuke default margins from checkboxes and radios to vertically center within.
  input[type="radio"],
  input[type="checkbox"] {
    margin-bottom: -3px;
  }
}

// Reset rounded corners

// Button input groups
// -------------------------
