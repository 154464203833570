//
// Carousel
// --------------------------------------------------


// Wrapper for the slide container and indicators
.carousel {
  width: 100%;
  padding: @carousel-padding;
  overflow: hidden;
  #gradient > .top-and-bottom(@start-color: @gray-lighter; @end-color:  @carousel-bg);
}


// Left/right controls for nav
// ---------------------------

.carousel-control {
  width: @carousel-control-width;
  .opacity(@carousel-control-opacity);
  color: @carousel-control-color;
  text-shadow: @carousel-text-shadow;

  // Set gradients for backgrounds
  &.left {
    background-image: none;
  }
  &.right {
    background-image: none;
  }

  // Hover/focus state
  &:hover,
  &:focus {
    color: @carousel-control-color;
  }

  // Toggles
  .icon-prev,
  .icon-next {
    &:before {
      content: '';
    }
  }
  .icon-prev {
    .chevron(left, 19px, #f5f5f5, 8px, 1.5px, #777);
    position: absolute;
    right: 0;
    // left: 0;
  }
  .icon-next {
    .chevron(right, 19px, #f5f5f5, 8px, 1.5px, #777);
    &:after,
    &:before {
      left: 50%;
    }
    position: absolute;
    right: 0;
    left: 50%;
  }
}

// Optional indicator pips
//
// Add an unordered list with the following class and add a list item for each
// slide your carousel holds.

.carousel-indicators {
  bottom: 5px;
  left: 0;
  width: 100%;
  margin-left: 0;

  li {
    background-color: @carousel-indicator-bg;
    border: 1px solid @carousel-indicator-border-color;
  }
  .active {
    margin: 1px;
    width:  10px;
    height: 10px;
    background-color: @carousel-indicator-active-bg;
    border: 1px solid @carousel-indicator-active-bg;
  }
}

// Optional captions
// -----------------------------
// Hidden by default for smaller viewports
.carousel-caption {
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10px;
  color: @carousel-caption-color;
  text-shadow: @carousel-text-shadow;
  background-color: @carousel-caption-fallback-bg; //old browsers fallback (ie8 etc)
  background-color: @carousel-caption-bg;
}
