//
// Navs
// --------------------------------------------------


// Base class
// --------------------------------------------------

.nav {
  > li {

    // Disabled state sets text to gray and nukes hover/tab effects
    &.disabled > a {
      color: @nav-disabled-link-color;

      &:hover,
      &:focus {
        color: @nav-disabled-link-hover-color;
      }
    }
  }

  // Open dropdowns
  .open > a {
    &,
    &:hover,
    &:focus {
      color: #fff;
      background-color: @nav-open-bg;
      border-color: @nav-open-bg;
    }
  }
}


// Tabs
// -------------------------

.nav-tabs {
  > li {

    // Actual tabs (as links)
    > a {
      border-radius: @border-radius-base @border-radius-base 0 0;
      color: @nav-tabs-link-color;
      &:hover {
        border-color: @nav-tabs-link-hover-border-color @nav-tabs-link-hover-border-color @nav-tabs-border-color;
      }
    }

    // Active state, and its :hover to override normal :hover
    &.active > a {
      &,
      &:hover,
      &:focus {
        font-weight: bold;
        color: @nav-tabs-active-link-hover-color;
      }
    }
  }
}


// Google Tabs
// -------------------------
.nav-tabs-google {
  > li {
    margin: 0 -1px 0 0;

    // Actual tabs (as links)
    > a {
      padding: @nav-tabs-google-link-padding-vertical @nav-tabs-google-link-padding-horizontal;
      line-height: @line-height-base;
      color: @nav-tabs-google-link-color;
      border: 3px solid transparent;
      border-width: 3px 0;
      border-radius: 0;
      margin: @nav-tabs-google-link-margin-vertical @nav-tabs-google-link-margin-horizontal;
      &:first-of-type {
        margin-left: 0;
      }
      &:hover,
      &:focus {
        background-color: transparent;
        border-top-color: transparent;
      }
      &:hover {
        color: @nav-tabs-google-link-hover-color;
        border-bottom-color: transparent;
      }
      &:active {
        color: @nav-tabs-google-link-active-color;
      }
      &:focus {
        color: @nav-tabs-google-link-focus-color;
        outline: 0;
      }
    }

    // Active state, and it's :hover to override normal :hover
    &.active > a {
      &,
      &:hover,
      &:focus {
        border: 3px solid transparent;
        border-width: 3px 0;
        color: @nav-tabs-google-link-active-color;
        border-bottom-color: @nav-tabs-google-link-selected-border-color;
      }
    }
  }
}

// Pills
// -------------------------
.nav-pills {
  > li {

    // Links rendered as pills
    > a {
      border-radius: @nav-pills-border-radius;
    }

    // Active state
    &.active > a {
      &,
      &:hover,
      &:focus {
        color: @nav-pills-active-link-hover-color;
        background-color: @nav-pills-active-link-hover-bg;
      }
    }
  }
}


// Nav variations
// --------------------------------------------------


// Dropdowns
// -------------------------
