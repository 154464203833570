//
// Labels
// --------------------------------------------------


// Base classes
.label {
  font-size: 80%;
  border-radius: 0;
}

// Colors
// Contextual variations (linked labels get darker on :hover)

.label-default {
  .label-variant(@label-default-bg);
}

.label-primary {
  .label-variant(@label-primary-bg);
}

.label-success {
  .label-variant(@label-success-bg);
}

.label-info {
  .label-variant(@label-info-bg);
}

.label-warning {
  .label-variant(@label-warning-bg);
}

.label-danger {
  .label-variant(@label-danger-bg);
}
