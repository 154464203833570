//
// Wells
// --------------------------------------------------


// Base class
.well {
  background-color: @well-bg;
  border: 1px solid @well-border;
  border-radius: 0;
  .box-shadow(none);
}

// Sizes
.well-lg {
  border-radius: 0;
}
.well-sm {
  border-radius: 0;
}
