//
// Code (inline and block)
// --------------------------------------------------


// Inline code
code {
  padding: 2px 4px;
  border-radius: 0;
}

// User input typically entered via keyboard
kbd {
  border-radius: @border-radius-small;
}

// Blocks of code
pre {
  padding: (@line-height-computed / 2);
  margin: 0 0( @line-height-computed / 2);
  font-size: (@font-size-base - 1); // 14px to 13px
  line-height: @line-height-base;
  border-radius: 0;
}
