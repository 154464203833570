//
// Tables
// --------------------------------------------------


table {
  background-color: @table-bg;
}
caption {
  color: @text-muted;
}


// Baseline styles

.table {
   margin-bottom: @line-height-computed;
  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        line-height: @line-height-base;
        border-top: 1px solid @table-border-color;
      }
    }
  }
  // Bottom align for column headings
  > thead > tr > th {
    border-bottom: 2px solid @table-border-color;
  }
  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 2px solid @table-border-color;
  }

  // Nesting
  .table {
    background-color: @body-bg;
  }
}



// Bordered version
//
// Add borders all around the table and between all the columns.

.table-bordered {
  border: 1px solid @table-border-color;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 1px solid @table-border-color;
      }
    }
  }
}



// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
  > tbody > tr:nth-of-type(odd) {
    background-color: @table-bg-accent;
  }
}



// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  > tbody > tr:hover {
    background-color: @table-bg-hover;
  }
}



// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

// Generate the contextual variants
.table-row-variant(warning; @state-warning-bg);


// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

.table-responsive {
  @media screen and (max-width: @screen-xs-max) {
    margin-bottom: (@line-height-computed * 0.75);
    border: 1px solid @table-border-color;
  }
}
