//
// Badges
// --------------------------------------------------


// Base class
.badge {
  font-size: @font-size-small;

  .btn-xs &,
  .btn-group-xs > .btn & {
    font-size: @font-size-mini;
  }

  // Account for badges in navs
  .list-group-item.active > &,
  li.list-group-item.active a > & {
    color: @badge-color;
    background-color: @list-group-active-color;
  }

  .nav-pills > .active > a > & {
    color: @link-color;
    background-color: @badge-active-bg;
  }
}
