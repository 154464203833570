//
// Alerts
// --------------------------------------------------


// Base styles
// -------------------------

//  for alert-default
//   text-shadow: none;
//   .box-shadow(0 2px 4px rgba(0,0,0,0.2));


.alert {
  padding: @alert-padding;
  margin-bottom: @line-height-computed;
  border-radius: @alert-border-radius;

  // Provide class for links that match alerts
  .alert-link {
    font-weight: @alert-link-font-weight;
  }
}

// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissable, // The misspelled .alert-dismissable was deprecated in 3.2.0.
.alert-dismissible {
  padding-right: (@alert-padding + 20);
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

.alert-success {
  .alert-variant(@alert-success-bg; @alert-success-border; @alert-success-text);
}

.alert-info {
  .alert-variant(@alert-info-bg; @alert-info-border; @alert-info-text);
}

.alert-warning {
  .alert-variant(@alert-warning-bg; @alert-warning-border; @alert-warning-text);
}

.alert-danger {
  .alert-variant(@alert-danger-bg; @alert-danger-border; @alert-danger-text);
}


.alert-success,
.alert-info,
.alert-warning,
.alert-danger {
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
