//
// Scaffolding
// --------------------------------------------------


// Body reset

body {
  font-family: @font-family-base;
  font-size: @font-size-base;
  line-height: @line-height-base;
  color: @text-color;
  background-color: @body-bg;
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}


// Links

a {
  color: @link-color;

  &:hover,
  &:focus {
    color: @link-hover-color;
  }
}


// Images

// Rounded corners
.img-rounded {
  border-radius: @border-radius-large;
}

// Image thumbnails
//
// Heads up! This is mixin-ed into thumbnails.less for `.thumbnail`.
.img-thumbnail {
  padding: @thumbnail-padding;
  line-height: @line-height-base;
  background-color: @thumbnail-bg;
  border: 3px solid @thumbnail-border;
  border-radius: @thumbnail-border-radius;
  .box-shadow(0 0 0 1px #aaaaaa);
  .transition(none);

  // Keep them at most 100% wide
  .img-responsive(inline-block);
}


// Miscellaneous

// Additional carets
.caret-right {
  &:extend(.caret);
  vertical-align: baseline;
  border-top: @caret-width-base solid transparent;
  border-right: 0 dotted;
  border-bottom: @caret-width-base solid transparent;
  border-left: @caret-width-base solid;
}

.caret-left {
  &:extend(.caret-right);
  margin-left: 0;
  margin-right: 2px;
  border-right: @caret-width-base solid;
  border-left: 0 dotted;
}


// Scrollable shadow
.scrollable-shadow {
  background:
    // Shadow covers
    linear-gradient(white 30%, rgba(255,255,255,0)),
    linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,

    // Shadows
    radial-gradient(50% 0, farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)),
    radial-gradient(50% 100%, farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
  background:
    // Shadow covers
    linear-gradient(white 30%, rgba(255,255,255,0)),
    linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,

    // Shadows
    radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll; // Opera doesn't support this in the shorthand
  background-size: 100% 40px, 100% 40px, 100% 6px, 100% 6px;
}
